<template>
  <DataTable
    :value="remittances"
    class="p-datatable-customers limitWidthCenter"
    dataKey="id"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="50"
    v-model:filters="filter"
    :globalFilterFields="['email']"
    :loading="loading"
    loadingIcon="fa fa-cog"
    contextMenu
    v-model:contextMenuSelection="selectedRemittance"
    @rowContextmenu="onRowContextMenu"
  >
    <template #loading>
      Loading records, please wait...
    </template>
    <template #header>
      <div class="fw-header">
        <div class="title-header">
          <div>
            <h6 class="mt-2 ml-2">
              <span class="p-menuitem-icon fas fa-stream" aria-hidden="true"></span> Remittances
            </h6>
          </div>
          <div>
            <Button
              icon="fas fa-plus"
              iconPos="right"
              class="p-button-rounded-light p-button-info ml-2"
            />
          </div>
        </div>
        <div class="w-full">
          <span class="p-input-icon-left w-full">
            <i class="fa fa-search" />
            <InputText
              v-model="filter['global'].value"
              placeholder="Search"
              :class="'fullWidthInput'"
              type="search"
            />
          </span>
        </div>

        <div>
          <button
            class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
            type="button"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>

    <Column field="id" header="ID" class="id-col" :sortable="true"></Column>
    <Column field="cocId" header="COC ID" class="chamber-col" :sortable="true">
      <template #body="{data}">
        {{ data.coc }}
      </template>
    </Column>
    <Column header="Date" class="date-col">
      <template #body="{data}">
        {{ formatDate(data.date) }}
      </template>
    </Column>
    <Column field="amount" header="Amount" :sortable="true">
      <template #body="{data}">
        {{ formatCurrency(data.amount) }}
      </template>
    </Column>
  </DataTable>

  <Dialog header="Remittance Entry" v-model:visible="showRemittanceForm" :modal="true">
    <remittance-form :chambers="chambers" />
  </Dialog>

  <ContextMenu :model="menuModel" ref="cm" />
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ContextMenu from "primevue/contextmenu";
import Dialog from "primevue/dialog";
import RemittanceForm from "./RemittanceForm.vue";
import { formatDate, formatCurrency } from "@/assets/js/format.js";

export default {
  name: "Remittances",
  components: { ContextMenu, Dialog, RemittanceForm },
  data() {
    return {
      showRemittanceForm: false,
      remittances: null,
      chambers: null,
      selectedRemittance: null,
      loading: false,
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      menuModel: [
        {
          label: "Edit",
          icon: "fas fa-fw fa-edit",
          command: () => this.edit(this.selectedRemittance)
        },
        {
          label: "Delete",
          icon: "fas fa-fw fa-times",
          command: () => this.delete(this.selectedRemittance)
        }
      ]
    };
  },
  created() {
    this.loading = true;
    this.axios.get("/remittance").then(response => {
      this.remittances = response.data;
      this.loading = false;
    });

    this.axios.get("/chambers").then(response => (this.chambers = response.data));
  }, //created()

  methods: {
    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent);
    },

    edit(remittance) {
      this.showRemittanceForm = true;
      console.log("Edit", remittance);
    }, //edit()

    delete(remittance) {
      console.log("Delete", remittance);
    }, //delete()

    formatDate,
    formatCurrency
  } //methods
};
</script>

<style></style>
