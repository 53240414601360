<template>
  <div class="modal-wrap">
    <div class="grid mt-2">
      <div class="col">
        <div class="p-float-label p-fluid">
          <InputNumber v-model="remittance.amount" mode="decimal" id="rmt-amt" />
          <label for="rmp-amt">Amount</label>
        </div>
      </div>
    </div>

    <div class="grid mt-3">
      <div class="col">
        <div class="p-float-label p-fluid">
          <Calendar v-model="remittance.date" :inline="false" id="rmt-date" />
          <label for="rmp-date">Date</label>
        </div>
      </div>
    </div>

    <div class="grid mt-3">
      <div class="col">
        <div class="p-float-label p-fluid">
          <Dropdown
            v-model="remittance.cocId"
            :options="chambers"
            optionValue="cocId"
            optionLabel="coc"
            id="rmt-chamber"
          />
          <label for="rmp-chamber">Chamber</label>
        </div>
      </div>
    </div>
    <div class="grid mt-3">
      <div class="col">
        <Checkbox id="rmt-notification" :binary="true" v-model="remittance.notify" />
          <label class="pl-2" for="rmt-notification">Send email notification.</label>
      </div>
    </div>

    <div class="grid mt-2">
      <div class="col text-right">
        <Button label="Save" />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import Checkbox from 'primevue/checkbox';

export default {
  name: "Remittance Form",
  components: { Dropdown, Calendar, InputNumber, Checkbox },
  props: ["chambers"],
  data() {
    return {
      remittance: {
        id: null,
        date: "2020-04-02",
        cocId: null,
        amount: null,
        notify: true
      }
    };
  }
};
</script>

<style></style>
