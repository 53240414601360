import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat)

import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

export const formatDate = date => {
    return dayjs(date).format("ll");
};

export const formatCurrency = number => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
};
